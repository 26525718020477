<template>
  <el-tag
    v-for="(tag, index) in tags"
    :key="index"
    class="mx-1"
    :closable="index === 0 ? false : true"
    @click="clickTag(tag)"
    @close="removeTag(tag, index)"
    :type="$route.path === tag.path ? '' : 'info'"
    :effect="$route.path === tag.path ? 'dark' : 'plain'"
  >
    {{ tag.name }}
  </el-tag>
</template>

<script>
import { usePermissionStore } from "@/store/permission";

export default {
  data() {
    return {
      tags: [],
    };
  },

  props: {
    routes: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  created() {
    this.tags = usePermissionStore().tags;
    let store = usePermissionStore();
    this.$watch(
      () => store.tags,
      (n) => {
        this.tags = n;
      }
    );
  },

  methods: {
    removeTag(item, index) {
      let tags = [...this.tags];
      tags.splice(index, 1);
      usePermissionStore().tags = tags;
      if (this.$route.path === item.path) {
        this.$router.push(tags[tags.length - 1].path);
      }
    },
    clickTag(tag) {
      this.$router.push(tag.path);
    },
  },
};
</script>
<style scoped>
.mx-1 {
  margin-right: 5px;
  cursor: pointer;
}
</style>
