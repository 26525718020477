import router from "./router/index";
import { useUserStore } from "@/store/user";
import { usePermissionStore } from "@/store/permission";

const whiteList = ["/Login", "/"]; // 白名单

router.beforeEach(async (to) => {
  const hasToken = localStorage.getItem("AccessToken");

  const userStore = useUserStore();
  const permissionStore = usePermissionStore();
  if (hasToken) {
    //判断token是否存在 存在即为已经登录
    if (to.path !== "/Login") {
      if (userStore.init) {
        // 获取了动态路由 init一定true,就无需再次请求 直接放行
        let addRoutes = [];
        permissionStore.addRoutes.map((item) => {
          addRoutes.push(item.path);
        });
        if (
          !router.hasRoute(to.path.substring(1)) &&
          addRoutes.indexOf(to.path) !== -1
        ) {
          console.log("刷新了,读缓存,重新创建动态路由");

          const accessRoutes = await permissionStore.generateRoutes(
            permissionStore.addRoutes
          ); //解析路由,存储路由
          // 动态挂载路由
          accessRoutes.forEach((route) => {
            router.addRoute(route);
          });

          return { ...to, replace: true };
        }
        if (addRoutes.indexOf(to.path) == -1) {
          return false;
        }
        return true;
      } else {
        // init为false,一定没有获取动态路由,就跳转到获取动态路由的方法
        const result = permissionStore.addRoutes;

        const accessRoutes = await permissionStore.generateRoutes(result); //解析路由,存储路由
        // 动态挂载路由
        accessRoutes.forEach((route) => {
          router.addRoute(route);
        });
        userStore.init = true; //init改为true,路由初始化完成
        console.log("init改为true,路由初始化完成");

        return { ...to, replace: true }; // hack方法 确保addRoute已完成
      }
    } else {
      let path = permissionStore.addRoutes[0].path;
      return path;
    }
  } else {
    // 白名单，直接放行
    if (whiteList.indexOf(to.path) > -1) return true;
    // 非白名单，去登录
    else return "/Login";
  }
});
