import { defineStore } from "pinia";
import Layout from "@/components/Layout2";

export const filterAsyncRoutes = (routerList) => {
  //进行递归解析
  //routerList 后端获取的路由
  const res = [];
  // console.log(testData);
  routerList.forEach((e) => {
    // console.log(e.component);
    let e_new = {
      path: e.path,
      name: e.name,
      component: null,
    };
    if (e.component === "layout") {
      e_new.component = Layout;
    } else {
      // console.log("22222",e.url);
      e_new.component = () => import(`../views${e.path}/index.vue`);
    }
    // console.log(e_new);
    if (e.children && e.children != null) {
      const children = filterAsyncRoutes(e.children);
      // 保存权限
      e_new = { ...e_new, children: children };
    }

    res.push(e_new);
  });
  // console.vlog("111",res);
  return res;
};

export const usePermissionStore = defineStore("permission", {
  id: "permission", // id必填，且需要唯一
  state: () => {
    return {
      addRoutes: [], //后端增加的路由
      tags: [],
      menus: [],
    };
  },
  actions: {
    generateRoutes(routes) {
      let routerList = JSON.parse(JSON.stringify(routes));
      // if (routerList[routerList.length - 1].path !== "/Game") {
      //   routerList.push({
      //     path: "/Game",
      //     component: "layout",
      //     children: [
      //       {
      //         path: "/Game",
      //         name: "Game",
      //         component: "Game",
      //       },
      //     ],
      //   });
      // }

      this.addRoutes = JSON.parse(JSON.stringify(routerList));

      return new Promise((resolve) => {
        const accessedRoutes = filterAsyncRoutes(routerList);
        resolve(accessedRoutes);
      });
    },
  },
  persist: true,
});
