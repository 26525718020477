<template>
  <div style="height: 60px">
    <header>
      <div class="logo-left">
        <img src="@/assets/logo.png" alt="" class="logo" />
        <span>摩哈云运营管理系统</span>
      </div>
      <el-button type="primary" @click="loginOut">退出</el-button>
    </header>
  </div>
  <el-menu :default-active="$route.path" class="menu">
    <template v-for="(item, index) in routes" :key="index">
      <el-menu-item
        :index="item.path"
        @click="handleMenuClick(item, index)"
        v-if="!item.children"
      >
        <img
          :src="item.icon1"
          alt=""
          style="width: 18px; margin-right: 2px"
          v-if="$route.path == item.path"
        />

        <img
          :src="item.icon"
          alt=""
          style="width: 18px; margin-right: 2px"
          v-else
        />

        <span>{{ item.name }}</span>
      </el-menu-item>
      <el-sub-menu :index="item.id + ''" v-else>
        <template #title>
          <img :src="item.icon" alt="" style="width: 18px; margin-right: 2px" />
          <span>{{ item.name }}</span>
        </template>
        <el-menu-item
          :index="item.path"
          @click="handleMenuClick(item, index)"
          v-for="(item, index) in item.children"
          :key="index + 'c'"
        >
          <img
            :src="item.icon1"
            alt=""
            style="width: 18px; margin-right: 2px"
            v-if="$route.path == item.path"
          />

          <img
            :src="item.icon"
            alt=""
            style="width: 18px; margin-right: 2px"
            v-else
          />
          <span>{{ item.name }}</span>
        </el-menu-item>
      </el-sub-menu>
    </template>
  </el-menu>
  <div style="height: 36px">
    <div class="tag-box">
      <Tag :routes="routes"></Tag>
    </div>
  </div>
  <main class="app-main">
    <div class="main-content">
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
import Tag from "./Tag.vue";
import { usePermissionStore } from "@/store/permission";
import { useUserStore } from "@/store/user";
export default {
  components: {
    Tag,
  },
  data() {
    return {
      routes: [],
    };
  },
  created() {
    this.routes = usePermissionStore().menus;
    this.routes.map((item) => {
      if (!item.path) {
        item.path = "null";
      }
    });
    console.log(this.routes);
    const map = function (arr) {
      arr.map((item) => {
        let icon = item.icon;
        if (item.icon.includes("base64")) {
          return;
        }
        item["icon"] = require(`../assets/${icon}.png`);
        item["icon1"] = require(`../assets/${icon}1.png`);
        if (item.children) {
          map(item.children);
        }
      });
    };
    map(this.routes);
    let tags = usePermissionStore().tags;
    let paths = tags.map((item) => item.path);
    if (paths.indexOf(this.$route.path) === -1) {
      let item = this.routes.filter((item) => item.path === this.$route.path);
      tags = [...tags];
      if (item[0]) {
        tags.push(item[0]);
        usePermissionStore().tags = tags;
      }
    }
  },
  methods: {
    handleMenuClick(item) {
      console.log(item);
      this.$router.push(item.path);

      let tags = usePermissionStore().tags;
      let paths = tags.map((item) => item.path);

      if (paths.indexOf(item.path) === -1) {
        tags = [...tags];
        tags.push(item);
        usePermissionStore().tags = tags;
      }
    },

    loginOut() {
      this.$confirm("确认退出登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        autofocus: false,
        type: "warning",
      })
        .then(() => {
          localStorage.removeItem("AccessToken");
          localStorage.removeItem("loginTime3");
          localStorage.removeItem("expireIns3");
          localStorage.removeItem("refreshToken3");
          localStorage.removeItem("username");
          useUserStore().$reset();
          usePermissionStore().$reset();

          this.$router.push("/Login");
          location.reload();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  height: 60px;
  background: #2b4b6b;
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 23px;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  .logo-left {
    display: flex;
    align-items: center;
  }
  .logo {
    width: 47px;
    height: 32px;
    margin-right: 11px;
  }
}
.menu {
  width: 210px;
  background: #f5f8ff;
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow-y: auto;
}
.icon {
  width: 22px;
  height: 18px;
  margin-right: 12px;
}
.tag-box {
  height: 36px;
  background: #fff;
  border-bottom: 1px solid #eee;
  padding-left: 20px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 60px;
  left: 210px;
  width: 100%;
}
.app-main {
  height: calc(100vh - 96px);
  box-sizing: border-box;
  margin-left: 210px;
  text-align: left;
  overflow: scroll;
  .tag-box {
    height: 35px;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: fixed;
    top: 60px;
    left: 210px;
    width: 100%;
  }
  .main-content {
    padding: 20px 20px 20px 20px;
  }
}
</style>
