import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  id: "user", // id必填，且需要唯一
  state: () => {
    return {
      init: false, // 是否已经保存了动态路由
    };
  },
  actions: {},
  persist: true,
});
