import { createRouter, createWebHashHistory } from "vue-router";
// import Layout from "@/components/Layout2";
//constantRoutes 静态路由 登陆。。。
export const constantRoutes = [
  {
    path: "/",
    redirect: "/Login",
  },
  {
    path: "/Login",
    name: "Login",
    meta: {
      title: "登录",
    },
    component: () => import("@/views/Login/index.vue"),
  },
];
//动态路由 asyncRoutes
export const asyncRoutes = [];
const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
});

export default router;
